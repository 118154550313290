//获奖证书
<template>
    <div>
        <van-grid :gutter="10" :border="false" :column-num="3" class="video-list">
            <van-grid-item  v-for="item in certificateType" :key="item.id" :item="item" :to="{name: 'certificateList', params: { certificateId: item.certificateListArr }}">
                <van-image :src="require('../../../assets/img/video/certificate.png')" />
                <span>{{item.idTypeName}}</span>
            </van-grid-item>
        </van-grid>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                certificateType: [{
                    "idType": 1,
                    "idTypeName" : "获奖证书",
                    "certificateListArr":"百强诚信经销商|/hjzs/1.png,荣誉证书|/hjzs/2.png,河南水灾捐赠证书|/hjzs/3.jpg"
                }]
            };
        }
    }
</script>
<style lang="less" scoped>
    .video-list span {
        font-size: 26px;
        margin-top: 20px;
         height: 76px;
    }
    .van-grid-item /deep/ .van-grid-item__content {
        padding-bottom: 0;
        border-radius: 10px!important;
    }
</style>