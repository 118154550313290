<template>
  <div class="freeAndCertification">
<!--    <van-notice-bar-->
<!--        left-icon="volume-o"-->
<!--        :text="errMsg"-->
<!--        color="#FB3333" background="#FFF7F7"-->
<!--        v-show="showErrMsg"-->
<!--    />-->
    <van-form>
      <!--    人脸识别-->
      <div class="identityAuthentication ">
        <div>
          <span>人脸识别</span>
          <span>*</span>
<!--          <span>请确保正脸完整</span>-->
          <span>请平视屏幕，并正对光源</span>
        </div>
        <div class="identityAuthenticationTxt">
<!--          <div>-->
<!--            <h3>人脸识别<span>正</span>面</h3>-->
<!--            <p>上传正面照</p>-->
<!--          </div>-->
          <div>
            <van-uploader :disabled="systemCertification" v-model="faceImg" :max-count="1" class="upIdCode" :after-read="afterRead" capture="user">
              <template>
                <img src="../../images/faceRecognition.png"/>
              </template>
            </van-uploader>
          </div>
          <div>点击识别</div>
        </div>
      </div>
      <!--    手持身份-->
      <div class="identityAuthentication ">
        <div>
          <span>上传手持身份证</span>
          <span>*</span>
          <span>请确保上传的照片</span>
          <span>信息真实有效、清晰可读</span>
        </div>
        <div class="identityAuthenticationTxt">
          <div>
            <van-uploader :deletable="artificialCertification" v-model="handImg" capture="user" :after-read="afterRead1" preview-size="105px" :max-count="1"
                          upload-icon="plus" style="padding-left: 0;margin-top: 10px;" class="upIdCode">
              <template>
                <img src="../../images/handIdCardicon@2x.png" v-show="imgShow"/>
                <img :src="handImg" v-show="imgShow1"/>
              </template>
            </van-uploader>
          </div>
          <div v-show="imgShow">点击识别</div>
        </div>
      </div>
      <!--    认证方式-->
      <div>
        <van-field
            v-model="type"
            label="证件类型"
            input-align="right"
            required
           disabled
        />
        <van-field
            v-model="realName"
            label="真实姓名"
            placeholder="请填写您的真实姓名"
            input-align="right"
            required
            :rules="[{ required: true }]"
            :disabled="readonlyTxt"
        />
        <van-field
            v-model="idCard "
            label="身份证号"
            placeholder="请填写您的身份证号"
            input-align="right"
            required
            :rules="[{ required: true }]"
            :disabled="readonlyTxt"
        />
      </div>

      <!--    所在地-->
      <div class="location">
        <van-form @submit="onSubmit">
          <van-field
              readonly
              clickable
              name="area"
              :value="nativePlace"
              label="所在地区"
              placeholder="请选择"
              @click="areaListShow = true"
              input-align="right"
              right-icon="arrow"
              required
          />
          <van-popup v-model="areaListShow" position="bottom" >
            <van-area
                :readonly = "areaDisabled"
                :area-list="areaList"
                @confirm="onConfirm"
                @cancel="areaListShow = false"
            />
          </van-popup>
        </van-form>
      </div>
      <div class="leasingAgreement ">
        <van-checkbox v-model="checked" label-disabled checked-color="#FB884F" icon-size="0.42rem">我已阅读并同意<span @click="goMianya">“免押金协议”</span>
        </van-checkbox>
      </div>
    </van-form>
    <!--    按钮-->
    <div class="btn">
      <van-button class="button"  @click="clickBtn" v-show="subBtn">提交申请</van-button>
      <van-button class="button" :disabled="disableSubBtn" @click="clickAmendBtn" v-show="amendBtn">修改/提交申请</van-button>
    </div>
  </div>
</template>

<script>
import {areaList} from '@vant/area-data';
import leaseApi from "../../../utils/leaseApi";
import {Toast} from 'vant';
import DEPOSIT from "../../../utils/deposit";
import award from "../../../views/video/child/award";

export default {
  name: "freeAndCertification",
  data() {
    return {
      // 城市列表
      areaList,
      areaListShow: false,
      // 城市数据
      addressValue: '',
      // 人脸照
      faceImg: [],
      // 手持照
      handImg: [{url:''}],
      // 真实姓名
      realName: '',
      // 身份证号
      idCard: '',
      // 所在地
      nativePlace: '',
      checked: 1,
      img: [],
      img1: [],
      subBtn:false,
      amendBtn:false,
      imgShow:false,
      systemCertification:'',
      artificialCertification:'',
      readonlyTxt:'',
      imgShow1:false,
      disableSubBtn:false,
      file:'',
      errMsg : '',
      showErrMsg : false,
      areaDisabled: false,
      type:'身份证认证'
    };

  },
  methods: {
    goMianya(){
      this.$router.push({name:'leasingAgreement'})
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    onConfirm(values) {
      this.nativePlace = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      this.areaListShow = false;
    },

    afterRead(file) {
      //判断图片的大小，单位是字节
      if (file.file.size > 1048576) {
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 300
          context.drawImage(img, 0, 0, 400, 300)
          file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
          let files = this.dataURLtoFile(file.content, file.file.name)
          console.log(files)
          this.img.push(files)
        }
      } else {
        // 符合大小的，直接上传
        this.img.push(file.file)
      }
    },
    afterRead1(file) {
      //判断图片的大小，单位是字节
      // if (file.file.size > 1048576) {
      //   console.log('压缩执行')
      //   let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
      //   let context = canvas.getContext('2d')
      //   let img = new Image()
      //   img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
      //   img.onload = () => {
      //     canvas.width = 475
      //     canvas.height =
      //     context.drawImage(img, 0, 0, 400, 300)
      //     file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
      //     let files = this.dataURLtoFile(file.content, file.file.name)
      //     this.img1.push(files)
      //   }
      // } else {
        // 符合大小的，直接上传
        this.img1.push(file.file)
      // }
    },

    // 将base64转换为file文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    // 获取免押回显
    async getFroms(){
      const { code,data}=await leaseApi.authenticationEcho()
     if(code==200){
       this.showErrMsg = true
       this.errMsg = data.remark
       this.amendBtn=true
       this.subBtn=false
       this.imgShow=true
       this.realName=data.realName
       this.idCard=data.idCard
       this.handImg = []
       this.nativePlace=data.nativePlace
       this.readonlyTxt=false
       this.imgShow1=true
       this.handImg = [{url:DEPOSIT + data.identityCardPicture + '?' + new Date().getTime() }]
       this.systemCertification= false
       this.areaDisabled = false
       // console.log(this.handImg[0])
       //系统认证通过 人工未通过
       if (data.systemCertification === 1){
         this.systemCertification=true
         this.readonlyTxt=true
         this.areaDisabled = true
       }
       //系统通过 人工通过
       if (data.systemCertification === 1 && data.artificialCertification === 1){
         this.imgShow=false
         this.imgShow1=true
         this.amendBtn=false
         this.subBtn=false
         this.areaDisabled = true
       }
     }else {
       this.amendBtn=false
       this.subBtn=true
       this.imgShow=true
       this.readonlyTxt=false
       this.handImg = []
       this.systemCertification=false
       this.artificialCertification=true
       this.imgShow1=true
     }
    },
    // 提交免押认证
    async clickBtn() {
      let reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
      if (this.img.length === 0 || this.img1.length === 0){
        Toast.fail("请上传照片")
        return;
      }
      if (this.idCard.length === 0 || this.realName.length === 0){
        Toast.fail("请输入姓名或者身份证")
        return;
      }
      if (!reg.test(this.idCard)){
        Toast.fail("请输入正确的身份证号码")
        return;
      }
      let regName = /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/;
      if (!regName.test(this.realName)){
        Toast.fail("请输入正确姓名")
        return;
      }
      if(this.nativePlace==""){
        return  Toast.fail("请选择所在地")
      }
      const froms = new FormData()
      froms.append("faceImg", this.img[0])
      froms.append("handImg", this.img1[0])
      froms.append("idCard", this.idCard)
      froms.append("nativePlace", this.nativePlace)
      froms.append("realName", this.realName)
      const {code,msg} = await leaseApi.authenticationIdCard(froms)
      if(code==200){
        await Toast.loading({
          message: '提交中...',
          forbidClick: true,
        });
        setTimeout(function (){
          Toast.loading({
            message:msg ,
            forbidClick: true,
            loadingType: 'spinner',
          });
          // this.$router.push({name:'personage'})
          window.location.href = 'http://maplays.net/#/personage'
        }.bind(this),2000)
      }else {
        Toast.fail({
          message: msg,
          forbidClick: true,
        });
      }
    },
    // 修改免押申请
    async clickAmendBtn(){
      let reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
      if (this.img.length === 0 && this.systemCertification === false){
        Toast.fail("请上传人脸照片")
        return;
      }
      if (this.idCard.length === 0 || this.realName.length === 0){
        Toast.fail("请输入姓名或者身份证")
        return;
      }
      if (this.nativePlace.length === 0){
        Toast.fail("请填写所在地")
        return;
      }
      if (!reg.test(this.idCard)){
        Toast.fail("请输入正确的身份证号码")
        return;
      }
      let regName = /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/;
      if (!regName.test(this.realName)){
        Toast.fail("请输入正确姓名")
        return;
      }
      const froms = new FormData()
      // const files=)
      froms.append("faceImg", this.img[0] === undefined ? new File(['text1', 'text2'], 'noneFace.txt', {type: 'text/plain'}) : this.img[0])
      froms.append("handImg", this.img1[0] === undefined ? new File(['text1', 'text2'], 'noneHandImg.txt', {type: 'text/plain'}) : this.img1[0])
      froms.append("idCard", this.idCard)
      froms.append("nativePlace", this.nativePlace)
      froms.append("realName", this.realName)
      console.log(this.img1[0])
      const {code,msg} = await leaseApi.authenticationUpdate(froms)
      if(code==200){
        await Toast.loading({
          message: "修改中...",
          forbidClick: true,
        });
        setTimeout(function (){
          Toast.loading({
            message:msg ,
            forbidClick: true,
            loadingType: 'spinner',
          });
          // this.$router.push({name:'personage'})
          window.location.href = 'http://maplays.net/#/personage'
        }.bind(this),2000)
      }else {
        Toast.loading({
          message: msg,
          forbidClick: true,
        });
      }
    }
  },
  created() {
    this.getFroms()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell--required::before {
  color: #FB884F;
  top: 0.37rem;
}
/deep/.van-cell{
  padding: 0.3rem 0.42667rem;
}
.freeAndCertification {
  background: RGBA(255, 254, 255, 1);
  //padding-top: 0.28rem;
  padding-bottom: 4rem;

  .identityAuthentication {
    background: #FFFFFF;
    padding:0.28rem;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      > span:nth-child(1) {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 550;
        color: #333;
        margin-left: 0.1rem;
      }

      > span:nth-child(2) {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #FF9966;
        margin-top: 0.15rem;
      }

      > span:nth-child(3) {
        //width: 5.21rem;
        //height: 0.29rem;
        font-size: 0.31rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
        line-height: 0.57rem;
        margin-left: 0.13rem;
      }

      > span:nth-child(4) {
        //width: 5.21rem;
        //height: 0.29rem;
        font-size: 0.31rem;
        font-family: xiaocheng;
        font-weight: 400;
        line-height: 0.57rem;
      }
    }

    .identityAuthenticationTxt {
      text-align: center;
      margin-top: 0.35rem;
      padding: 0.52rem 0.36rem;
      border-bottom: 0.01rem solid #EFEFEF;
      > div:nth-child(1) {
        .upIdCode {
          img {
            width: 3.33rem;
          }
        }

      }
      >div:nth-child(2){
        font-size: 0.37rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 0.43rem;
      }
    }
  }
}

.authenticationTxt {
  position: relative;

  .authenticationPic {
    position: absolute;
    top: 0.35rem;
    left: 2.15rem;
    display: flex;

    img {
      width: 0.42rem;
      height: 0.42rem;
      align-self: center;
    }

    p {
      font-size: 0.39rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #292929;
      margin-top: 0rem;
      align-self: center;
    }
  }
}

.leasingAgreement {
  margin-bottom: 0.28rem;
  background: #FFFFFF;
  border-radius: 0.28rem;
  font-size: 0.36rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #333333;
  padding: 0.4rem 0.3rem 0.4rem 0.3rem;
  margin-top: 0.28rem;
  span {
    color: #FF9966;
  }
}

.location {
  overflow: hidden;
}

.btn {
  margin-top: 0.28rem;
  background: #FFFFFF;
  padding: 0.28rem 0rem;
  text-align: center;
  position: fixed;
  bottom: 1.6rem;
  width: 100%;
  .button {
    width: 9.75rem;
    height: 1.11rem;
    background: #FB884F;
    //box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
    border-radius: 1rem;
    font-size: 0.44rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.11rem;
    //text-align: center;
    //margin-left: 50%;
    //transform: translateX(-50%);
  }
}
[class*=van-hairline]::after{
  border: 0px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-field__control:disabled{
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #292929;
  -webkit-text-fill-color:#292929
}
/deep/.van-cell::after{
  border-bottom:0.01rem solid #EFEFEF;
}
</style>
